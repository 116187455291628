import { logout, getInfo } from '@/api/login';
import { loginApi } from '@/api/api';
import { getToken, setToken, removeToken } from '@/utils/auth';

const user = {
  state: {
    token: getToken(),
    id: '',
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            setToken(res.token);
            localStorage.setItem('user', JSON.stringify(res.user || {}));
            commit('SET_TOKEN', res.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
        // if (user.roles && user.roles.length > 0) {
        //   // 验证返回的roles是否是一个非空数组
        //   commit('SET_ROLES', user.roles);
        //   commit('SET_PERMISSIONS', user.permissions);
        // } else {
        //   commit('SET_ROLES', ['ROLE_DEFAULT']);
        // }
        commit('SET_ID', user.id);
        commit('SET_NAME', user.name);
        commit('SET_AVATAR', user.headImageUrl);
        resolve(user);
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        removeToken();
        resolve();
      });
    }
  }
};

export default user;
